import React, {useState} from 'react'
import {Link} from 'gatsby'
import Lottie from 'react-lottie';

import styles from './museuvirtual.module.css'

import home from '../images/home.svg'

import animationData from '../animations/seta.json';

function Museu () {

  const [isOpen, setIsOpen] = useState(false);
  const [animationState, setAnimationState] = useState({ 
    isStopped: true, isPaused: false,
    direction: -1,
  });

  const defaultOptions = {
    loop: false,
    autoplay: false, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={styles.root}>
      {/* <Link to="/">
        <button className={styles.button}><img src={home} alt="Página inicial" /></button>
      </Link> */}
      
      <div className={styles.museumenu}>
        <div className={styles.menucontent}>
          <input type="checkbox" id="toggle"/>

          <div className={styles.linkini}>  
            <Link to="/"><p>Página Inicial</p></Link>
          </div>

          <label for="toggle"
            className={styles.label}
            onClick={() => {
              const reverseAnimation = -1;
              const normalAnimation = 1;

              setAnimationState({
                ...animationState,
                isStopped: false,
                direction: animationState.direction === normalAnimation 
                  ? reverseAnimation
                  : normalAnimation,
              }),
              setIsOpen(!isOpen)
            }}>
            <Lottie options={defaultOptions}
              height={24}
              width={24}
              direction={animationState.direction}
              isStopped={animationState.isStopped}
              isPaused={animationState.isPaused}
            />
          </label>
        </div>
      </div>
      
      <iframe width="100%" height="100%" data-original-width="640" data-original-height="320" src="https://www.thinglink.com/mediacard/1435024917799108609" type="text/html" 
      frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen scrolling="no"></iframe>
      <script async src="//cdn.thinglink.me/jse/responsive.js"></script>
    </div>
  )
}

export default Museu
